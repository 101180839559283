// src/CampaignContent.js
import React from 'react';
import { Box, Heading, Text, VStack, Divider } from '@chakra-ui/react';

const CampaignContentBefore = () => {
    return (
        <VStack spacing={4} align="stretch">
            <Box>
                <Heading size="lg" mb={4}>Take Action on the War and Humanitarian Crisis in Sudan</Heading>
                <Text fontSize="md">Make Your Opinion Heard on the Ongoing Crisis in Sudan</Text>
            </Box>

            <Divider />

            <Text>
            We are now a full year into the war in Sudan, and Western nations have, for the most part, failed to take any meaningful action despite the demands of the diaspora. As you read this message, understand that during the past year, Sudan has been transformed into a “humanitarian nightmare” and “catastrophe,” according to UN officials. Today, over 10.7 million have been forcibly displaced by the war, with 9 million of those being IDPs, constituting the world’s largest internal displacement crisis. An estimated 24 million people require immediate humanitarian assistance, 18 million are suffering from acute hunger, 15 million lack access to health care, and 4 million women and girls are at risk of sexual violence, though these statistics barely scratch the surface of the level of devastation in Sudan. Moreover, a genocide carried out by the Rapid Support Forces (RSF) currently ravages Darfur, with horrific reports of massacres, mass rape, and gender-based violence emerging from the region.            </Text>
            <Text>
            As constituents in Western nations, we have a responsibility to demand action on the parts of our governments, who all played a role in the legitimization of military dictatorship in Sudan and the dismantling of transitional democracy. 
            </Text>
            <Text>
            As such, we have put together this site and these email templates to convey our demands to your representatives. Simply enter the country you live in, state, and zip code. Once you click submit, you will be directed to a pre-written email, which you can either send as is or edit as you see fit.
            </Text>

            <Heading size="md" mt={6}>How It Works</Heading>
            <Text>
                <strong>Enter Your Details:</strong>  Start by providing your zip code and state. This information will help us identify not only your senators but also your local House representative.
            </Text>
            <Text>
                <strong>Review Your Message:</strong> A pre-drafted email will be prepared, conveying a strong message reflecting your demands to representatives.
            </Text>
            <Text>
                <strong>Send with Your Email Client:</strong> Once you've reviewed the message, clicking "Submit" will open your default email application, allowing you to send it directly from your own account.
            </Text>
            <Text>
                <strong>Make Your Voice Heard:</strong> While our platform does not track sent emails, we encourage you to follow up on the response from your representatives regarding related legislation.
            </Text>
        </VStack>
    );
};

export default CampaignContentBefore;
