const config = {
    emailSubject: 'Take Action on the War and Humanitarian Crisis in Sudan',
    emailSoS: 'secretary@state.gov',
    emailBodySoS: `Dear Secretary of State Antony Blinken,

I am writing to you with regards to the war taking place in Sudan between the Sudanese Armed Forces (SAF) and the Rapid Support Forces (RSF), which has now been raging for a full year. 
    
Fighting between the SAF and the RSF has impacted all 18 provinces, crippling infrastructure, placing between 70% and 80% of hospitals out of service, and resulting in grave violations of human rights as violence, abuse, and exploitation of women and children reach unprecedented levels by way of killing, maiming, child recruitment, sexual violence, and arbitrary detention. Furthermore, the RSF in particular has been found to be complicit in each of these crimes, including the accusation of genocide in West Darfur, following the ethnic cleansing campaigns carried out in El Gineina and Ardamata, which the ICC has opened an investigation into, and the entry and occupation of homes along with looting, raping, and killing of residents.
    
During the past year, Sudan has been transformed into a “humanitarian nightmare” and “catastrophe,” according to UN officials. Today, over 10.7 million have been forcibly displaced by the war, with 9 million of those being IDPs, constituting the world’s largest internal displacement crisis. An estimated 24 million people require immediate humanitarian assistance, 18 million are suffering from acute hunger, 15 million lack access to health care, and 4 million women and girls are at risk of sexual violence. These horrifying metrics, however, barely scratch the surface of the true toll of the war on the Sudanese people, only increasing in devastation as the war continues on, and are also vastly underreported due to limited resources in the nation.
    
In the meantime, the international community has thrown together a number of haphazard initiatives to bring the warring parties together and negotiate an end to the war, an option which has clearly proven unsuccessful across the past year given the fact that powerful players such as the United States have been unwilling to apply pressure on critical allies funding this war and arming the two parties. 
    
Ultimately, the Sudanese people have played no part in this conflict; two forces currently fight to rule a country, of which neither of them were chosen by its people. It was the international community, including the United States, that waysided the civilians and legitimized the rule of the military, accepting the coup on October 25, 2021 that laid the groundwork for this war with the belief that it would lead to personal gain. Now, the international community, and more specifically, the United States, bears a responsibility to the Sudanese people and its large Sudanese diaspora to provide humanitarian relief to civilians and to apply pressure to help mediate an end to the conflict.
    
Today, the revised 2024 Sudan Humanitarian Response Plan (HRP) requires $2.7 billion to provide life-saving multi-sectoral and protection assistance to 14.7 million people in desperate need through the end of this year. As of April, only $145 million has been donated, with the US contributing roughly $16.3 million. This total constitutes a mere 5% of the level of need estimated months ago, meaning that the global community has quite simply failed to meet even a fraction of the level of humanitarian assistance required in Sudan. In short, Sudan needs more humanitarian aid funding, at a much more rapid pace, to close in on the gap of the increasing humanitarian assistance needed among Sudanese people.
    
In comparison, though Sudan’s humanitarian toll has surpassed that of Ukraine, the United States has provided over $9 billion exclusively in humanitarian assistance to Ukraine and its neighboring nations, which is over 9 times that provided to Sudan and its neighbors, not to mention the amount provided in military, security and financial aid, which altogether has topped $76.8 billion. 
    
Thus, given the fact that the United States clearly bears ample funding for foreign aid and its complicity in the perpetuation of the crisis in Sudan, the United States has a moral obligation to address the humanitarian crisis in Sudan. I call on you, Secretary Blinken, for tangible support of the Sudanese people - ensuring they receive equitable and just support rather than falling victim to selective empathy or inadequate differentiating systems. Below are our demands:

    1. Call for an immediate ceasefire. The U.S. should utilize effective pressure to support a broad international coalition to achieve an immediate humanitarian ceasefire, a monitoring mechanism, safe corridors for humanitarian aid, and resumption of suspended aid operations.

    2. Increase development and humanitarian aid to NGOs operating internally in Sudan focused on food and medical aid distribution. The United States government should ensure the safety of food and medical aid to the Sudanese people through NGOs such as the World Food Programme and the Red Crescent. Sudan is on the brink of famine with 43% of the population suffering from acute food insecurity, and diseases are spreading. The United States government should work to ensure the safe passage of humanitarian aid convoys.

    3. Apply pressure on key allies involved in the arming of the warring parties. It is quite clear that the war in Sudan is unsustainable without the foreign allies driving the conflict by arming and financing the two parties, making Sudan’s conflict akin to a proxy war. We call on the US government to leverage its relationships and apply targeted pressure on nations such as the United Arab Emirates and Egypt, whose involvement in the war has led to war crimes, crimes against humanity, and ethnic cleansing.

    4. Apply the Arms Export Control Act and Enforce International Traffic in Arms Regulations and Export Administration Regulations on Sudan. The U.S. Government should strictly enforce current legislative tools, like the Arms Export Control Act, and enforce governmental mechanisms to prevent the export and proliferation of military-relevant items directly and indirectly to Sudan, including preventing the illicit flow of arms to non-state actors and private military companies.

    5. Work on the international level to expand the UN Arms Embargo on Sudan and Consider Additional Mechanisms. The U.S. should utilize its role as a permanent member of the UNSC to reaffirm, renew, and expand the existing UN arms embargo and other sanctions on Darfur, Sudan to include the entire Sudan, key individuals in SAF and RSF, and human rights violators.

The Sudanese people believe in freedom, peace, and justice. They gave their lives to bring about democracy in the nation, and the international community, and more specifically, the United States betrayed them through the legitimization of military forces.
    
Will you act now, Secretary Blinken, to ensure that the United States government is supporting the Sudanese diaspora in the US by meeting the requirements stated above before the crisis further worsens?`,


    houseRepTemplate: (senderName, repName, state, zipCode) => `Dear Representative ${repName},

My name is ${senderName} and I am your constituent in ${state}.

I am writing to you with regards to the war taking place in Sudan between the Sudanese Armed Forces (SAF) and the Rapid Support Forces (RSF), which has now been raging for a full year. 

Fighting between the SAF and the RSF has impacted all 18 provinces, crippling infrastructure, placing between 70% and 80% of hospitals out of service, and resulting in grave violations of human rights as violence, abuse, and exploitation of women and children reach unprecedented levels by way of killing, maiming, child recruitment, sexual violence, and arbitrary detention. Furthermore, the RSF in particular has been found to be complicit in each of these crimes, including the accusation of genocide in West Darfur, following the ethnic cleansing campaigns carried out in El Gineina and Ardamata, which the ICC has opened an investigation into, and the entry and occupation of homes along with looting, raping, and killing of residents.

During the past year, Sudan has been transformed into a “humanitarian nightmare” and “catastrophe,” according to UN officials. Today, over 10.7 million have been forcibly displaced by the war, with 9 million of those being IDPs, constituting the world’s largest internal displacement crisis. An estimated 24 million people require immediate humanitarian assistance, 18 million are suffering from acute hunger, 15 million lack access to health care, and 4 million women and girls are at risk of sexual violence. These horrifying metrics, however, barely scratch the surface of the true toll of the war on the Sudanese people, only increasing in devastation as the war continues on, and are also vastly underreported due to limited resources in the nation.

In the meantime, the international community has thrown together a number of haphazard initiatives to bring the warring parties together and negotiate an end to the war, an option which has clearly proven unsuccessful across the past year given the fact that powerful players such as the United States have been unwilling to apply pressure on critical allies funding this war and arming the two parties. 

Ultimately, the Sudanese people have played no part in this conflict; two forces currently fight to rule a country, of which neither of them were chosen by its people. It was the international community, including the United States, that waysided the civilians and legitimized the rule of the military, accepting the coup on October 25, 2021 that laid the groundwork for this war with the belief that it would lead to personal gain. Now, the international community, and more specifically, the United States, bears a responsibility to the Sudanese people and its large Sudanese diaspora to provide humanitarian relief to civilians and to apply pressure to help mediate an end to the conflict.

Today, the revised 2024 Sudan Humanitarian Response Plan (HRP) requires $2.7 billion to provide life-saving multi-sectoral and protection assistance to 14.7 million people in desperate need through the end of this year. As of April 2024, only $145 million has been donated, with the US contributing roughly $16.3 million. This total constitutes a mere 5% of the level of need estimated months ago, meaning that the global community has quite simply failed to meet even a fraction of the level of humanitarian assistance required in Sudan. In short, Sudan needs more humanitarian aid funding, at a much more rapid pace, to close in on the gap of the increasing humanitarian assistance needed among Sudanese people.

In comparison, though Sudan’s humanitarian toll has surpassed that of Ukraine, the United States has provided over $9 billion exclusively in humanitarian assistance to Ukraine and its neighboring nations, which is over 9 times that provided to Sudan and its neighbors, not to mention the amount provided in military, security and financial aid, which altogether has topped $76.8 billion. 

Thus, given the fact that the United States clearly bears ample funding for foreign aid and its complicity in the perpetuation of the crisis in Sudan, the United States has a moral obligation to address the humanitarian crisis in Sudan. I call on you, ${repName}, for tangible support of the Sudanese people - ensuring they receive equitable and just support rather than falling victim to selective empathy or inadequate differentiating systems. Below are our demands:

    1. Call for an immediate ceasefire. The U.S. should utilize effective pressure to support a broad international coalition to achieve an immediate humanitarian ceasefire, a monitoring mechanism, safe corridors for humanitarian aid, and resumption of suspended aid operations. 

    2. Increase development and humanitarian aid to NGOs operating internally in Sudan focused on food and medical aid distribution. The United States government should ensure the safety of food and medical aid to the Sudanese people through NGOs such as the World Food Programme and the Red Crescent. Sudan is on the brink of famine with 43% of the population suffering from acute food insecurity, and diseases are spreading. The United States government should work to ensure the safe passage of humanitarian aid convoys.

    3. Apply pressure on key allies involved in the arming of the warring parties. It is quite clear that the war in Sudan is unsustainable without the foreign allies driving the conflict by arming and financing the two parties, making Sudan’s conflict akin to a proxy war. We call on the US government to leverage its relationships and apply targeted pressure on nations such as the United Arab Emirates and Egypt, whose involvement in the war has led to war crimes, crimes against humanity, and ethnic cleansing.

    4. Apply the Arms Export Control Act and Enforce International Traffic in Arms Regulations and Export Administration Regulations on Sudan. The U.S. Government should strictly enforce current legislative tools, like the Arms Export Control Act, and enforce governmental mechanisms to prevent the export and proliferation of military-relevant items directly and indirectly to Sudan, including preventing the illicit flow of arms to non-state actors and private military companies.

    5. Work on the international level to expand the UN Arms Embargo on Sudan and Consider Additional Mechanisms. The U.S. should utilize its role as a permanent member of the UNSC to reaffirm, renew, and expand the existing UN arms embargo and other sanctions on Darfur, Sudan to include the entire Sudan, key individuals in SAF and RSF, and human rights violators.

The Sudanese people believe in freedom, peace, and justice. They gave their lives to bring about democracy in the nation, and the international community, and more specifically, the United States betrayed them through the legitimization of military forces.

Will you act now,  ${repName}, to ensure that the United States government is supporting the Sudanese diaspora in the US by meeting the requirements stated above before the crisis further worsens?`,
   

    senateRepTemplate: (senderName, rep1Name, rep2Name, state) => `Dear Senators ${rep1Name} and ${rep2Name},

My name is ${senderName} and I am your constituent in the state of ${state}.

I am writing to you with regards to the war taking place in Sudan between the Sudanese Armed Forces (SAF) and the Rapid Support Forces (RSF), which has now been raging for a full year. 

Fighting between the SAF and the RSF has impacted all 18 provinces, crippling infrastructure, placing between 70% and 80% of hospitals out of service, and resulting in grave violations of human rights as violence, abuse, and exploitation of women and children reach unprecedented levels by way of killing, maiming, child recruitment, sexual violence, and arbitrary detention. Furthermore, the RSF in particular has been found to be complicit in each of these crimes, including the accusation of genocide in West Darfur, following the ethnic cleansing campaigns carried out in El Gineina and Ardamata, which the ICC has opened an investigation into, and the entry and occupation of homes along with looting, raping, and killing of residents.

During the past year, Sudan has been transformed into a “humanitarian nightmare” and “catastrophe,” according to UN officials. Today, over 10.7 million have been forcibly displaced by the war, with 9 million of those being IDPs, constituting the world’s largest internal displacement crisis. An estimated 24 million people require immediate humanitarian assistance, 18 million are suffering from acute hunger, 15 million lack access to health care, and 4 million women and girls are at risk of sexual violence. These horrifying metrics, however, barely scratch the surface of the true toll of the war on the Sudanese people, only increasing in devastation as the war continues on, and are also vastly underreported due to limited resources in the nation.

In the meantime, the international community has thrown together a number of haphazard initiatives to bring the warring parties together and negotiate an end to the war, an option which has clearly proven unsuccessful across the past year given the fact that powerful players such as the United States have been unwilling to apply pressure on critical allies funding this war and arming the two parties. 

Ultimately, the Sudanese people have played no part in this conflict; two forces currently fight to rule a country, of which neither of them were chosen by its people. It was the international community, including the United States, that waysided the civilians and legitimized the rule of the military, accepting the coup on October 25, 2021 that laid the groundwork for this war with the belief that it would lead to personal gain. Now, the international community, and more specifically, the United States, bears a responsibility to the Sudanese people and its large Sudanese diaspora to provide humanitarian relief to civilians and to apply pressure to help mediate an end to the conflict.

Today, the revised 2024 Sudan Humanitarian Response Plan (HRP) requires $2.7 billion to provide life-saving multi-sectoral and protection assistance to 14.7 million people in desperate need through the end of this year. As of April 2024, only $145 million has been donated, with the US contributing roughly $16.3 million. This total constitutes a mere 5% of the level of need estimated months ago, meaning that the global community has quite simply failed to meet even a fraction of the level of humanitarian assistance required in Sudan. In short, Sudan needs more humanitarian aid funding, at a much more rapid pace, to close in on the gap of the increasing humanitarian assistance needed among Sudanese people.

In comparison, though Sudan’s humanitarian toll has surpassed that of Ukraine, the United States has provided over $9 billion exclusively in humanitarian assistance to Ukraine and its neighboring nations, which is over 9 times that provided to Sudan and its neighbors, not to mention the amount provided in military, security and financial aid, which altogether has topped $76.8 billion. 

Thus, given the fact that the United States clearly bears ample funding for foreign aid and its complicity in the perpetuation of the crisis in Sudan, the United States has a moral obligation to address the humanitarian crisis in Sudan. I call on you, ${rep1Name} and ${rep2Name}, for tangible support of the Sudanese people - ensuring they receive equitable and just support rather than falling victim to selective empathy or inadequate differentiating systems. Below are our demands:

    1. Call for an immediate ceasefire. The U.S. should utilize effective pressure to support a broad international coalition to achieve an immediate humanitarian ceasefire, a monitoring mechanism, safe corridors for humanitarian aid, and resumption of suspended aid operations.

    2. Increase development and humanitarian aid to NGOs operating internally in Sudan focused on food and medical aid distribution. The United States government should ensure the safety of food and medical aid to the Sudanese people through NGOs such as the World Food Programme and the Red Crescent. Sudan is on the brink of famine with 43% of the population suffering from acute food insecurity, and diseases are spreading. The United States government should work to ensure the safe passage of humanitarian aid convoys.

    3. Apply pressure on key allies involved in the arming of the warring parties. It is quite clear that the war in Sudan is unsustainable without the foreign allies driving the conflict by arming and financing the two parties, making Sudan’s conflict akin to a proxy war. We call on the US government to leverage its relationships and apply targeted pressure on nations such as the United Arab Emirates and Egypt, whose involvement in the war has led to war crimes, crimes against humanity, and ethnic cleansing.

    4. Apply the Arms Export Control Act and Enforce International Traffic in Arms Regulations and Export Administration Regulations on Sudan. The U.S. Government should strictly enforce current legislative tools, like the Arms Export Control Act, and enforce governmental mechanisms to prevent the export and proliferation of military-relevant items directly and indirectly to Sudan, including preventing the illicit flow of arms to non-state actors and private military companies.

    5. Work on the international level to expand the UN Arms Embargo on Sudan and Consider Additional Mechanisms. The U.S. should utilize its role as a permanent member of the UNSC to reaffirm, renew, and expand the existing UN arms embargo and other sanctions on Darfur, Sudan to include the entire Sudan, key individuals in SAF and RSF, and human rights violators.

The Sudanese people believe in freedom, peace, and justice. They gave their lives to bring about democracy in the nation, and the international community, and more specifically, the United States betrayed them through the legitimization of military forces.

Will you act now, ${rep1Name} an d ${rep2Name}, to ensure that the United States government is supporting the Sudanese diaspora in the US by meeting the requirements stated above before the crisis further worsens?`,

    getRepresentativesApiUrl: 'https://5abimagwh5.execute-api.us-east-1.amazonaws.com/dev/get-representatives' // Your deployed API url here
};

export default config;
